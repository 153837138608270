import styled from "@emotion/styled"

export const Content = styled.div`
  a {
    display: block;
    height: 63px;
    margin: 0 auto;
    width: 293px;
  }
  img {
    margin: 0;
  }
`
